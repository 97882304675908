<template>
    <div class="interactive-manage">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
            <el-breadcrumb-item class="first">工作台</el-breadcrumb-item>
            <el-breadcrumb-item class="second">设置</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="editPwd">

        </div>
    </div>
</template>
<script>
export default {
  name: "dataSpectaculars",
  data(){
    return{

    }
   
  },
  mounted() {}

}

</script>
<style scoped lang="scss">
  .interactive-manage {
        min-height: calc(100vh - 100px);
    }
    .editPwd{
        display: flex;
        min-height: calc(100vh - 192px);
        margin-top: 17px;
        min-height: 440px;
            background-color: #fff;
            border-radius: 10px;
            margin-right: 17px;
    }
</style>
